<app-breadcrumbs [items]="items"></app-breadcrumbs>
<div class="container-fluid mt-6 p-4">
  <div class="row justify-content-between">
    <div class="col-4">
      <h1 class="heading">Pipeline List</h1>
    </div>
    <div class="col-2">
      <mat-form-field appearance="outline">
        <input matInput (keyup)="applyFilter($event)" placeholder="Search">
      </mat-form-field>
    </div>
  </div>
  <div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef><b>S.No.</b></th>
        <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef><b>Name</b></th>
        <td mat-cell class="column-align" *matCellDef="let element">
          <button (click)="navigate(element)" class="button-link">{{element.name}}</button>
          <button class="button-link"></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="sync">
        <th mat-header-cell *matHeaderCellDef><b>Sync</b></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="refresh(element);" class="button-link"
            [ngClass]="element.inSync ? 'rotate' : ''">
            <mat-icon>sync</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="sourcestatus">
        <th mat-header-cell *matHeaderCellDef><b>Source Status</b></th>
        <td mat-cell [ngClass]="getStatusClass(element.state[0].latestExecution.status)" *matCellDef="let element">
          {{element.state[0].latestExecution.status}}</td>
      </ng-container>

      <ng-container matColumnDef="buildstatus">
        <th mat-header-cell *matHeaderCellDef><b>Build Status</b></th>
        <td mat-cell [ngClass]="getStatusClass(element.state[1].latestExecution.status)" *matCellDef="let element">
          {{element.state[1].latestExecution.status}}</td>
      </ng-container>

      <ng-container matColumnDef="executed">
        <th mat-header-cell *matHeaderCellDef><b>Last Executed</b></th>
        <td mat-cell *matCellDef="let element">{{element.executed}}</td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef><b>Created</b></th>
        <td mat-cell *matCellDef="let element">{{element.created}}</td>
      </ng-container>

      <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef><b>Updated</b></th>
        <td mat-cell *matCellDef="let element">{{element.updated}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
