import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { SessionService } from './shared/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string = 'internal-pipeline-ui';
  authStatus = false;
  staticData!: any;
  authSub: Subscription = new Subscription;

  constructor(private sessionService: SessionService, private router: Router) { }

  ngOnInit(): void {
    this.authSub = this.sessionService.authStatus.subscribe(() => {
      this.setAuthStatus();
    });
  }

  setAuthStatus(): void {
    this.authStatus = this.sessionService.isLoggedIn();
    if (this.authStatus === false) {
      this.router.navigate(['login']);
    }
  }

  getAuthStatus() {
    return this.sessionService.isLoggedIn();
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
  }
}
