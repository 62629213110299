import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { PipelineDataService } from '../../shared/services/pipeline-data.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { BreadcrumbService } from '../../shared/services/breadcrumb.service';
import { Breadcrumb } from '../../shared/models/breadcrumb';
import { ServicesDataService } from '../../shared/services/services-data.service';

@Component({
  selector: 'app-log-streams',
  templateUrl: './log-streams.component.html',
  styleUrls: ['./log-streams.component.css', '../../app.component.css']
})
export class LogStreamsComponent implements OnInit {

  logGroupName!: string;
  logStreamName!: string;
  items!: Breadcrumb[];
  selectedValue!: string;

  constructor(private pipelineDataService: PipelineDataService, private router: Router,
    public activatedRoute: ActivatedRoute, private spinnerService: SpinnerService,
    private breadcrumbService: BreadcrumbService, private servicesDataService: ServicesDataService) { }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    if (this.router.url.indexOf('/pipeline') > -1) {
      this.selectedValue = 'pipeline';
    }
    else {
      this.selectedValue = 'services';
    }
    this.items = this.breadcrumbService.createBreadcrumbs();
    this.activatedRoute.params.subscribe(param =>
      this.logGroupName = param['name']);

    this.getLogStreams();
  }
  logStreamDataSource: any;

  getLogStreams() {
    if (this.router.url.indexOf('/pipeline') > -1) {
      this.getPipelineLogStreams();
    }
    else {
      this.getServiceLogStreams();
    }
  }

  getPipelineLogStreams() {
    this.pipelineDataService.getLogStreamsData(this.logGroupName).subscribe(data => {
      data.forEach(element => {
        element.creationTime = new Date(element.creationTime).toString(),
          element.firstEventTimestamp = new Date(element.firstEventTimestamp).toString(),
          element.lastEventTimestamp = new Date(element.lastEventTimestamp).toString(),
          element.lastIngestionTime = new Date(element.lastIngestionTime).toString()
      });
      this.logStreamDataSource = new MatTableDataSource(data)
    })
  }

  getServiceLogStreams() {
    this.servicesDataService.getCompleteServiceLogStreamsData(this.logGroupName).subscribe(data => {
      data.forEach(element => {
        element.creationTime = new Date(element.creationTime).toString(),
          element.firstEventTimestamp = new Date(element.firstEventTimestamp).toString(),
          element.lastEventTimestamp = new Date(element.lastEventTimestamp).toString(),
          element.lastIngestionTime = new Date(element.lastIngestionTime).toString()
      });
      this.logStreamDataSource = new MatTableDataSource(data)
    })
  }

  navigate(logStreamName: string) {
    this.logStreamName = logStreamName
    if (this.router.url.indexOf('/pipeline') > -1) {
      this.router.navigateByUrl("home/pipeline/" + this.logGroupName + "/logStreams/" + this.logStreamName);
    }
    else {
      let url = "home/services/" + this.logGroupName + "/logStreams/" + encodeURIComponent(this.logStreamName);
      this.router.navigateByUrl(url);
    }
  }
  displayedColumns: string[] = ['index', 'logStreamName', 'creationTime', 'firstEventTimestamp', 'lastEventTimestamp', 'lastIngestionTime']
}
