import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, map, catchError, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Pipeline, PipelineObject, PipelineStage } from '../models/pipelineObject';
import { Execution, ExecutionObject } from '../models/executionObject';
import { LogStreams, LogStreamsObject } from '../models/logStreamObject';
import { LogEvents, LogEventsObject } from '../models/logEventsObject';
import { LatestPipelineStatusObject } from '../models/latestPipelineStatusObject';

@Injectable({
    providedIn: 'root'
})
export class PipelineDataService {
    constructor(private httpClient: HttpClient) { }

    getPipelineList(): Observable<Pipeline[]> {
        const url: string = environment.BASE_API + environment.GET_PIPELINE_DATA_API;
        return this.httpClient.get<PipelineObject>(url)
            .pipe(
                map((response: PipelineObject) => response.pipelines),
                catchError(this.handleError)
            );
    }

    getPipelineLatestStatus(pipelineName: string): Observable<PipelineStage[]> {
        const url: string = environment.BASE_API + environment.GET_PIPELINE_LATEST_DATA_API;
        let data = {
            pipeline_name: pipelineName
        };
        return this.httpClient.get<LatestPipelineStatusObject>(url, { params: data })
            .pipe(
                map((response: LatestPipelineStatusObject) => response.state),
                catchError(this.handleError)
            );
    }

    getExecutionList(pipelineName: string): Observable<Execution[]> {
        const url: string = environment.BASE_API + environment.GET_EXECUTIONS_API;
        let data = {
            pipeline_name: pipelineName
        };
        return this.httpClient.get<ExecutionObject>(url, { params: data })
            .pipe(
                map((response: ExecutionObject) => response.executions),
                catchError(this.handleError)
            );
    }

    getLogStreamsData(logGroupName: string): Observable<LogStreams[]> {
        const url: string = environment.BASE_API + environment.GET_PIPELINE_LOGSTREAMS_API;
        let data = {
            loggroup_name: logGroupName
        };
        return this.httpClient.get<LogStreamsObject>(url, { params: data })
            .pipe(
                map((response: LogStreamsObject) => response.logstreams),
                catchError(this.handleError)
            );
    }

    getLogEventsData(logGroupName: string, logStreamName: string): Observable<LogEvents[]> {
        const url: string = environment.BASE_API + environment.GET_PIPELINE_LOGEVENTS_API;
        let data = {
            loggroup_name: logGroupName,
            logstream_name: logStreamName
        };
        return this.httpClient.get<LogEventsObject>(url, { params: data })
            .pipe(
                map((response: LogEventsObject) => response.logevents),
                catchError(this.handleError)
            );
    }

    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        }
        return throwError(() => {
            return errorMessage;
        });
    }
}