import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { Breadcrumb } from '../../shared/models/breadcrumb';
import { BreadcrumbService } from '../../shared/services/breadcrumb.service';
import { ServicesDataService } from '../../shared/services/services-data.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css', '../../app.component.css']
})
export class ServiceComponent implements OnInit {

  items!: Breadcrumb[];
  selectedValue!: string;
  
  constructor(private servicesDataService: ServicesDataService, private route: Router, private router: ActivatedRoute,
    private spinnerService: SpinnerService, private breadcrumbService: BreadcrumbService) { }
  ngOnInit(): void {
    this.fetchServiceLogGroups();
  }
  dataSource: MatTableDataSource<string> = new MatTableDataSource<string>();

  fetchServiceLogGroups() {
    this.spinnerService.showSpinner();
    if (this.route.url.indexOf('/pipeline') > -1) {
      this.selectedValue = 'pipeline';
    }
    else {
      this.selectedValue = 'services';
    }
    let array = this.route.url.split('/');
    array.shift();
    if (array.length > 1) {
      this.items = this.breadcrumbService.createBreadcrumbs('/home/services');
    }
    else{
      this.items = this.breadcrumbService.createBreadcrumbs();
    }
    this.servicesDataService.getCompleteServiceList().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
    });
  }

  applyFilter($event: any) {
    let abc = ($event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = abc;
  }

  navigate(service: string) {
    this.route.navigateByUrl("home/services/" + service + "/logStreams");
  }
  displayedColumns: string[] = ['index', 'name']
}