<app-breadcrumbs [items]="items"></app-breadcrumbs>
<div class="container-fluid mt-6 p-4">
  <div class="row justify-content-between">
    <div class="col-4">
      <h1 class="heading">Service Log Groups</h1>
    </div>
    <div class="col-2">
      <mat-form-field appearance="outline">
        <input matInput (input)="applyFilter($event)" placeholder="Search">
      </mat-form-field>
    </div>
  </div>
  <div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
      <ng-container matColumnDef="index">
        <th mat-header-cell class="column-align header-align" *matHeaderCellDef><b>S.No.</b></th>
        <td mat-cell class="column-align" *matCellDef="let element; let i = index;">{{i+1}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef><b>Name</b></th>
        <td mat-cell *matCellDef="let element">
          <button (click)="navigate(element)" class="button-link">{{element}}</button>
          <button class="button-link"></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>