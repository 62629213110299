export const environment = {
  production: false,
  BASE_API: "https://dev.api.pipeline.wginb.com",
  LOGIN_API: "/wginbpipelinelogin/login",
  GET_PIPELINE_DATA_API: "/wginbpipeline/pipeline/list",
  GET_PIPELINE_LATEST_DATA_API: "/wginbpipeline/pipeline/executions/latest",
  GET_EXECUTIONS_API: "/wginbpipeline/pipeline/executions",
  GET_PIPELINE_LOGSTREAMS_API: "/wginbpipeline/pipeline/log/streams",
  GET_PIPELINE_LOGEVENTS_API: "/wginbpipeline/pipeline/log/events",
  GET_SERVICE_DATA_API: "/wginbpipeline/svc/log/groups",
  GET_SERVICE_LOGSTREAMS_API: "/wginbpipeline/svc/log/streams",
  GET_SERVICE_LOGEVENTS_API: "/wginbpipeline/svc/log/events"
}
