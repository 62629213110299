import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { PipelineDataService } from '../../shared/services/pipeline-data.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { BreadcrumbService } from '../../shared/services/breadcrumb.service';
import { Breadcrumb } from '../../shared/models/breadcrumb';
import { ServicesDataService } from '../../shared/services/services-data.service';

@Component({
  selector: 'app-log-events',
  templateUrl: './log-events.component.html',
  styleUrls: ['./log-events.component.css']
})
export class LogEventsComponent implements OnInit {

  logGroupName!: string;
  logStreamName!: string;
  items!: Breadcrumb[];
  selectedValue!: string;

  constructor(private pipelineDataService: PipelineDataService, private breadcrumbService: BreadcrumbService, private router: Router,
    public activatedRoute: ActivatedRoute, private spinnerService: SpinnerService, private servicesDataService: ServicesDataService) { }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    if (this.router.url.indexOf('/pipeline') > -1) {
      this.selectedValue = 'pipeline';
    }
    else {
      this.selectedValue = 'services';
    }
    this.items = this.breadcrumbService.createBreadcrumbs();
    this.activatedRoute.params.subscribe(param =>
      this.logGroupName = param['logGroupName']);

    this.activatedRoute.params.subscribe(param =>
      this.logStreamName = param['logStreamName']);

    this.getLogEvents();
  }
  logStreamDataSource: any;

  getLogEvents() {
    if (this.router.url.indexOf('/pipeline') > -1) {
      this.getPipelineLogEvents();
    }
    else {
      this.getServiceLogEvents();
    }
    
  }

  getPipelineLogEvents()
  {
    this.pipelineDataService.getLogEventsData(this.logGroupName, this.logStreamName).subscribe(data => {
      data.forEach(element => {
        element.timestamp = new Date(element.timestamp).toString();
      });
      this.logStreamDataSource = new MatTableDataSource(data);
    });
  }

  getServiceLogEvents()
  {
    this.servicesDataService.getCompleteServiceLogEventsData(this.logGroupName, this.logStreamName).subscribe(data => {
      data.forEach(element => {
        element.timestamp = new Date(element.timestamp).toString();
      });
      this.logStreamDataSource = new MatTableDataSource(data);
    });
  }

  applyFilter($event: any) {
    this.logStreamDataSource.filter = $event.target.value.trim().toLowerCase();
  }
  displayedColumns: string[] = ['index', 'timestamp', 'message']
}
