import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { LoginRequest, LoginResponse } from '../../shared/models/authentication/auth.model';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { SessionService } from '../../shared/services/session.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginData: LoginRequest = {};

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private sessionService: SessionService, private spinnerService: SpinnerService) { }

  onSubmit(loginForm: NgForm): void {
    if (loginForm.valid) {
      this.spinnerService.showSpinner();
      this.authenticationService.login(this.loginData).subscribe((response: LoginResponse) => {
       this.sessionService.updateAuthStatus(true);
            this.sessionService.isLoggedIn();
      this.router.navigate(['home']);
      });
    }
  }

}
