<app-breadcrumbs [items]="items"></app-breadcrumbs>
<div class="container-fluid mt-6 p-4">
  <div class="row justify-content-between">
    <div class="col-4">
      <h1 class="heading">Log Events</h1>
    </div>
    <div class="col-2">
      <mat-form-field appearance="outline">
        <input matInput (keyup)="applyFilter($event)" placeholder="Search">
      </mat-form-field>
    </div>
  </div>
  <table mat-table [dataSource]="logStreamDataSource" class="mat-elevation-z8 demo-table">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef><b>S.No.</b></th>
      <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef class="timestamp-class"><b>Timestamp</b></th>
      <td mat-cell *matCellDef="let element">{{element.timestamp | date: 'medium'}}</td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef><b>Message</b></th>
      <td mat-cell *matCellDef="let element">{{element.message}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>