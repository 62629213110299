import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  imagePath: string = './assets/images/wg_logo.png';

  constructor(private authService: AuthenticationService,
    private router: Router) { }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}
