import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from '@angular/material/tabs';

import { ServiceComponent } from "./service/service.component";
import { HomeRoutingModule } from "./home-routing.module";
import { HomeComponent } from "./home.component";
import { PipelineListComponent } from "./pipeline-list/pipeline-list.component";
import { ExecutionListComponent } from "./execution-list/execution-list.component";
import { LogStreamsComponent } from "./log-streams/log-streams.component";
import { LogEventsComponent } from "./log-events/log-events.component";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { TabsComponent } from "./tabs/tabs.component";
import { DashboardComponent } from "./dashboard/dashboard.component";

@NgModule({
  imports: [
    MatInputModule,
    CommonModule,
    HomeRoutingModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [
    ServiceComponent,
    HomeComponent,
    PipelineListComponent,
    ExecutionListComponent,
    LogStreamsComponent,
    LogEventsComponent,
    BreadcrumbsComponent,
    TabsComponent,
    DashboardComponent
  ]
})
export class HomeModule { }