import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Breadcrumb } from '../models/breadcrumb';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {

  path!: string;
  constructor(private router: Router) {
    this.path = this.router.url;
  }

  createBreadcrumbs(breadcrumbLabels?: string): Breadcrumb[] {

    let path!: string;
    if(breadcrumbLabels != undefined)
    {
      path = breadcrumbLabels;
    }
    else{
      path = this.router.url;
    }
    const breadcrumbs: Breadcrumb[] = [];

    let array = path.split('/');
    array.shift();

    array.forEach(element => {
      let data: Breadcrumb = {
        label: decodeURIComponent(element),
        url: ''
      }
      breadcrumbs.push(data)
    });

    for (let index = breadcrumbs.length - 1; index >= 0; index--) {
      breadcrumbs[index].url = path;
      let valueToReplace = '/' + array[index]
      path = path.replace(valueToReplace, '')
    }
    return breadcrumbs;
  }
}