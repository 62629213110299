import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
    Observable, throwError, scan,
    expand, takeWhile, concatMap
} from 'rxjs';

import { environment } from '../../../environments/environment';
import { LogStreams, LogStreamsObject } from '../models/logStreamObject';
import { LogEvents, LogEventsObject } from '../models/logEventsObject';
import { ServiceObject } from '../models/serviceObject';

@Injectable({
    providedIn: 'root'
})
export class ServicesDataService {
    constructor(private httpClient: HttpClient) { }

    getServiceLogGroupsList(url: string): Observable<ServiceObject> {
        return this.httpClient.get<ServiceObject>(url);
    }

    getServiceLogStreamsData(url: string, params: any) {
        return this.httpClient.get<LogStreamsObject>(url, { params: params })
    }

    getServiceLogEventsData(url: string, data: any, nexttoken?: string) {
        let params!: any;
        if(nexttoken !== undefined)
        {
            params = {
                loggroup_name: data.loggroup_name,
                logstream_name: data.logstream_name,
                nexttoken: decodeURIComponent(nexttoken)
            };
        }
        else{
            params = data;
        }
        return this.httpClient.get<LogEventsObject>(url, { params: params });
    }

    getCompleteServiceList(): Observable<string[]> {
        let url: string = environment.BASE_API + environment.GET_SERVICE_DATA_API;

        return this.getServiceLogGroupsList(url)
            .pipe(
                expand((res: ServiceObject) => this.getServiceLogGroupsList(url + '?nexttoken=' + res.nexttoken)),
                takeWhile((res: ServiceObject) => res.nexttoken !== null, true),
                concatMap((res: ServiceObject) => res.loggroups),
                scan((acc: string[], val: string) => {
                    acc.push(val);
                    return acc;
                }, [])
            );
    }

    getCompleteServiceLogStreamsData(logGroupName: string): Observable<LogStreams[]> {
        const url: string = environment.BASE_API + environment.GET_SERVICE_LOGSTREAMS_API;
        let data = {
            loggroup_name: logGroupName
        };

        return this.getServiceLogStreamsData(url, data)
            .pipe(
                expand((res: LogStreamsObject) => this.getServiceLogStreamsData(url + '?nexttoken=' + res.nexttoken, data)),
                takeWhile((res: LogStreamsObject) => res.logstreams.length > 0, true),
                concatMap((res: LogStreamsObject) => res.logstreams),
                scan((acc: LogStreams[], val: LogStreams) => {
                    acc.push(val);
                    return acc;
                }, [])
            );
    }

    getCompleteServiceLogEventsData(logGroupName: string, logStreamName: string): Observable<LogEvents[]> {
        const url: string = environment.BASE_API + environment.GET_SERVICE_LOGEVENTS_API;
        let data = {
            loggroup_name: logGroupName,
            logstream_name: logStreamName
        };

        return this.getServiceLogEventsData(url, data)
            .pipe(
                expand((res: LogEventsObject) => this.getServiceLogEventsData(url, data, res.nexttoken)),
                takeWhile((res: LogEventsObject) => res.nexttoken !== null, true),
                concatMap((res: LogEventsObject) => res.logevents),
                scan((acc: LogEvents[], val: LogEvents) => {
                    acc.push(val);
                    return acc;
                }, [])
            );
    }

    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        }
        return throwError(() => {
            return errorMessage;
        });
    }
}