import { Router } from '@angular/router'
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { SessionService } from './session.service';
import { environment } from "../../../environments/environment";
import { LoginRequest, LoginResponse } from '../models/authentication/auth.model';

export const InterceptorSkip = 'X-Skip-Interceptor';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    fetchUsersAction = new BehaviorSubject(false);
    onFormReset = new Subject<void>();
    InterceptorSkipHeader = new HttpHeaders({
      'X-Skip-Interceptor': ''
    });
    loggedIn = false

    constructor(private http: HttpClient, private router: Router, private sessionService: SessionService){}

    resetForm(): void {
        this.onFormReset.next();
    }

    login(loginData: LoginRequest): Observable<LoginResponse> {
        return this.http.post<any>(environment.BASE_API+environment.LOGIN_API,
          loginData,
          { headers: this.InterceptorSkipHeader })
          .pipe(
            map((response: LoginResponse) => {
              this.sessionService.setSessionObject(response);
              return response;
            })
          );
      }

    logout(): void{
        this.sessionService.clearSession();
        this.router.navigate(['/login']);
    }
}
