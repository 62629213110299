<app-breadcrumbs [items]="items"></app-breadcrumbs>
<div class="container-fluid mt-6 p-4">
  <h1 class="heading">LogStreams</h1>
  <table mat-table [dataSource]="logStreamDataSource" class="mat-elevation-z8 demo-table">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef><b>S.No.</b></th>
      <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
    </ng-container>

    <ng-container matColumnDef="logStreamName">
      <th mat-header-cell *matHeaderCellDef><b>LogStream Name</b></th>
      <td mat-cell *matCellDef="let element">
        <button (click)="navigate(element.logStreamName)" class="button-link">{{element.logStreamName}}</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="creationTime">
      <th mat-header-cell *matHeaderCellDef><b>CreationTime</b></th>
      <td mat-cell *matCellDef="let element">{{element.creationTime | date: 'medium'}}</td>
    </ng-container>

    <ng-container matColumnDef="firstEventTimestamp">
      <th mat-header-cell *matHeaderCellDef><b>FirstEventTimestamp</b></th>
      <td mat-cell *matCellDef="let element">{{element.firstEventTimestamp | date: 'medium'}}</td>
    </ng-container>

    <ng-container matColumnDef="lastEventTimestamp">
      <th mat-header-cell *matHeaderCellDef><b>LastEventTimestamp</b></th>
      <td mat-cell *matCellDef="let element">{{element.lastEventTimestamp | date: 'medium'}}</td>
    </ng-container>

    <ng-container matColumnDef="lastIngestionTime">
      <th mat-header-cell *matHeaderCellDef><b>LastIngestionTime</b></th>
      <td mat-cell *matCellDef="let element">{{element.lastIngestionTime | date: 'medium'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>