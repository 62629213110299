<app-breadcrumbs [items]="items"></app-breadcrumbs>
<div class="container-fluid mt-6 p-4">
  <h1 class="heading">Pipeline List Executions</h1>
  <table mat-table [dataSource]="executionDataSource" class="mat-elevation-z8 demo-table">
    <ng-container matColumnDef="index">
      <th mat-header-cell *matHeaderCellDef><b>S.No.</b></th>
      <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
    </ng-container>

    <ng-container matColumnDef="pipelineExecutionId">
      <th mat-header-cell *matHeaderCellDef><b>Pipeline ExecutionId</b></th>
      <td mat-cell *matCellDef="let element">
        <button (click)="navigate()" class="button-link">{{element.pipelineExecutionId}}</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef><b>Status</b></th>
      <td mat-cell [ngClass]="getStatusClass(element.status)" *matCellDef="let element">{{element.status}}</td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef><b>StartTime</b></th>
      <td mat-cell *matCellDef="let element">{{element.startTime}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>