<div class="w-100 d-flex flex-column gap-4 align-items-center mt-5">
    <img class="logo img-fluid" src="../../../assets/images/wg_logo1.jpg" >
    <h1>WGINB Pipeline</h1>
    <div>
        <form #loginForm = "ngForm" (ngSubmit)="onSubmit(loginForm)">
            <div class="d-flex flex-column gap-4 align-items-center mt-3">
                <div class="d-flex gap-2 align-items-center">
                    <label for="uname"><b>Username</b></label>
                    <input class="form-control" type="text" placeholder="Enter Username" name="uname" required [(ngModel)] = "loginData.username">
                </div>
                <div class="d-flex gap-2 align-items-center">
                    <label for="psw"><b>Password</b></label>
                    <input class="form-control" type="password" placeholder="Enter Password" name="psw" required [(ngModel)] = "loginData.password">
                </div>
                <button class="btn btn-secondary" type="submit">Login</button>
            </div>
        </form>
    </div>
</div>
