import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home.component';
import { ServiceComponent } from './service/service.component';
import { PipelineListComponent } from './pipeline-list/pipeline-list.component';
import { ExecutionListComponent } from './execution-list/execution-list.component';
import { LogStreamsComponent } from './log-streams/log-streams.component';
import { LogEventsComponent } from './log-events/log-events.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const homeRoutes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      { path: '', component: DashboardComponent },
      { path: 'services', component: ServiceComponent },
      { path: 'pipeline', component: PipelineListComponent },
      { path: 'pipeline/:name', component: ExecutionListComponent },
      { path: 'services/:name', component: ServiceComponent },
      { path: 'pipeline/:name/logStreams', component: LogStreamsComponent },
      { path: 'services/:name/logStreams', component: LogStreamsComponent },
      { path: 'pipeline/:logGroupName/logStreams/:logStreamName', component: LogEventsComponent },
      { path: 'services/:logGroupName/logStreams/:logStreamName', component: LogEventsComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(homeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class HomeRoutingModule { }