import { Component, Input } from '@angular/core';

import { Breadcrumb } from '../../shared/models/breadcrumb';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent{
  @Input() items: Breadcrumb[] = [];
}