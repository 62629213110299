import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { PipelineDataService } from '../../shared/services/pipeline-data.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { Breadcrumb } from '../../shared/models/breadcrumb';
import { BreadcrumbService } from '../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-execution-list',
  templateUrl: './execution-list.component.html',
  styleUrls: ['./execution-list.component.css', '../../app.component.css']
})
export class ExecutionListComponent implements OnInit {

  pipelineName!: string;
  items!: Breadcrumb[];
  selectedValue!: string;

  constructor(private awsapicallservice: PipelineDataService, private router: Router,
    public activatedRoute: ActivatedRoute, private spinnerService: SpinnerService,
    public breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    if (this.router.url.indexOf('/pipeline') > -1) {
      this.selectedValue = 'pipeline';
    }
    else {
      this.selectedValue = 'services';
    }
    this.items = this.breadcrumbService.createBreadcrumbs();
    this.activatedRoute.params.subscribe(param =>
      this.pipelineName = param['name']);
    this.getExecutionData();
  }
  executionDataSource: any;

  getExecutionData() {
    this.awsapicallservice.getExecutionList(this.pipelineName).subscribe(data => {
      this.executionDataSource = new MatTableDataSource(data)
    })
  }

  getStatusClass(status: string) {
    if (status === 'Succeeded')
      return "success"

    if (status === 'Failed')
      return "failure"

    return "inProcess"
  }

  navigate() {
    this.router.navigateByUrl("home/pipeline/" + this.pipelineName + "/logStreams");
  }

  displayedColumns: string[] = ['index', 'pipelineExecutionId', 'status', 'startTime']
}
