<div class="d-flex flex-column p-2 nav-section">
    <div class="d-flex justify-content-between">
        <div>
            <img [src] = "imagePath" class="img-fluid wg-logo">
        </div>
        <div>
            <h1>WGINB Pipeline</h1>
        </div>
        <div class="d-flex align-items-center">
            <button (click)="logout()" class="m-0 btn btn-secondary">Logout</button>
        </div>
    </div>
</div>