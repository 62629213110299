import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { __values } from 'tslib';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import { Session } from '../models/authentication/auth.model';

@Injectable({ providedIn: 'root' })

export class SessionService {
    authStatus = new BehaviorSubject(false);

    constructor(private router: Router) { }
    updateAuthStatus(status: boolean): void {
        this.authStatus.next(status);
    }
    setSessionObject(sessionData: Session): void {
        sessionStorage.setItem("wginb-session", JSON.stringify(sessionData));
        this.authStatus.next(true);
    }

    getSessionData(value: keyof Session): string | number {
        const session: Session = JSON.parse(sessionStorage.getItem('wginb-session')!);
        return session[value];
    }

    getTokenData(value: keyof Session | string): string {
        const session: Session = JSON.parse(sessionStorage.getItem('wginb-session')!);
        const tokenData: any = jwt_decode(session.idtoken);
        return tokenData[value];
    }

    isLoggedIn(): boolean {
        const session: Session = JSON.parse(sessionStorage.getItem('wginb-session')!);
        if (session === null) {
            return false;
        } else {
            const value: any = "exp";
            const timeExpired = new Date().getTime() > (parseInt(this.getTokenData(value), 10)) * 1000;
            return !timeExpired;
        }
    }

    clearSession(): void {
        sessionStorage.removeItem('wginb-session');
        this.authStatus.next(false);
    }
}
