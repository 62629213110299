import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { ToastService } from '../components/toast/toast.service';
import { SpinnerService } from '../components/spinner/spinner.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService, private spinnerService: SpinnerService,
        private toastService: ToastService, private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map((response: any) => {
            if (response.status === 200) {
                this.spinnerService.hideSpinner();
                if (request.method !== 'GET') {
                    this.toastService.showMessage("Action Successful", 'success');
                }
            }
            return response;
        }), catchError(errorResponse => {
            this.spinnerService.hideSpinner();
            if (errorResponse.status === 401 || errorResponse.status === 403) {
                this.toastService.showMessage('Unauthorized', 'error', 'Please login Again');
                this.authenticationService.logout();
                this.router.navigate(['/login']);
            } else if (errorResponse.status === 500) {
                this.toastService.showMessage(errorResponse.error.message, 'error', errorResponse.error.body);
            } else {
                this.toastService.showMessage('Something bad happened', 'error', 'Please try Again');
            }
            const error = errorResponse.error.message || errorResponse.statusText;
            return throwError(error);
        }));
    }
}
