import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { constants } from '../../shared/models/constants'
import { Pipeline, PipelineStage } from '../../shared/models/pipelineObject';
import { PipelineDataService } from '../../shared/services/pipeline-data.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { Breadcrumb } from '../../shared/models/breadcrumb';
import { BreadcrumbService } from '../../shared/services/breadcrumb.service';

@Component({
  selector: 'app-pipeline-list',
  templateUrl: './pipeline-list.component.html',
  styleUrls: ['./pipeline-list.component.css', '../../app.component.css']
})
export class PipelineListComponent implements OnInit {

  items: Breadcrumb[] = [];
  selectedValue!: string;
  constructor(private awsapicallservice: PipelineDataService, private route: Router, private router: ActivatedRoute,
    private spinnerService: SpinnerService, private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.fetchPipeline();
  }
  dataSource: MatTableDataSource<Pipeline> = new MatTableDataSource<Pipeline>();

  fetchPipeline() {
    this.spinnerService.showSpinner();
    if (this.route.url.indexOf('/pipeline') > -1) {
      this.selectedValue = 'pipeline';
    }
    else {
      this.selectedValue = 'services';
    }
    this.items = this.breadcrumbService.createBreadcrumbs();
    this.awsapicallservice.getPipelineList().subscribe((data: Pipeline[]) => {
      this.dataSource = new MatTableDataSource(data);
    });
  }

  getStatusClass(status: string) {
    if (status === constants.Success_Status)
      return constants.Success_Class;

    if (status === constants.Failed_Status)
      return constants.Failed_Class;

    return constants.InProcess_Class;
  }

  refresh(pipeline: Pipeline) {
    pipeline.inSync = true;
    pipeline.state[0].latestExecution.status = constants.Loading_Status;
    pipeline.state[1].latestExecution.status = constants.Loading_Status;

    this.awsapicallservice.getPipelineLatestStatus(pipeline.name).subscribe((data: PipelineStage[]) => {
      pipeline.state = data;
      pipeline.inSync = false;
    });
  }

  applyFilter($event: any) {
    this.dataSource.filter = ($event.target as HTMLInputElement).value.trim().toLowerCase();
  }

  navigate(pipeline: Pipeline) {
    this.route.navigate([pipeline.name], { relativeTo: this.router });
  }
  displayedColumns: string[] = ['index', 'name', 'sync', 'sourcestatus', 'buildstatus', 'executed', 'created', 'updated']
}
